import { NETWORK_TYPE, SetInterface } from './index'

export const web3Sets: { [key in NETWORK_TYPE]?: SetInterface } = {
  Ropsten: {
    erc20Token: '0x18f04e1BFA5C0F28DB6AB07dA4E7b01cD40ed349',
    vptToken: '0xcda4e840411c00a614ad9205caec807c7458a0e3',
    voting: '0x21714E06198534518567256184951126d62b3Db6',
    bridge: '0xe76A25d69F54091A08bd9938c22BE8b794a615CF',
  },
}

export const thorSets = {
  stacking: '0x751f7fe0236378918ee03514c1aaafaf702f282d',
  hai: '0xaCc280010B2EE0efc770BCE34774376656D8cE14',
}
